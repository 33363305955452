import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  DefaultDataServiceConfig,
  HttpMethods,
  HttpUrlGenerator,
} from '@ngrx/data';
import { Observable, defer, iif, of, throwError } from 'rxjs';
import { switchMap, throwIfEmpty } from 'rxjs/operators';

import { IFlashyServerResponse } from './interfaces';

export class FlashyDataServiceService<T> extends DefaultDataService<T> {
  constructor(
    entityName: string,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    config: DefaultDataServiceConfig
  ) {
    super(entityName, http, httpUrlGenerator, {
      ...config,
      delete404OK: false,
    });
  }

  protected execute(
    method: HttpMethods,
    url: string,
    execData?: object,
    options?: object
  ): Observable<T | T[]> {
    return super
      .execute(method, url, execData, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        switchMap(({ success, data, errors }: IFlashyServerResponse<T>) =>
          iif(
            () => success,
            defer(() => of(data)),
            throwError(errors)
          )
        ),
        throwIfEmpty(() => new Error('NOT REPORTING ERROR'))
      );
  }
}
