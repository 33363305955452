import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FlashyStoreService } from './store.service';

// import {FlashyContactType} from './models/contact/flashy-contact-data.service';

export type TFlashyCommands =
  | 'export_contacts'
  | 'export_list'
  | 'delete_contacts_segment';

@Injectable({
  providedIn: 'root',
})
export class FlashyCommandsService {
  constructor(private flashyStoreService: FlashyStoreService) {}

  exportContacts(email: string, segment_id = 0): Observable<boolean> {
    return this.execute({ email, segment_id }, 'export_contacts');
  }

  deleteContactsSegment(segment_id = 0): Observable<boolean> {
    return this.execute(
      { segment_id, silent: true },
      'delete_contacts_segment'
    );
  }

  exportList(
    email: string,
    list_id: number,
    type: string
  ): Observable<boolean> {
    return this.execute({ email, list_id, type }, 'export_contacts');
  }

  private execute<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: Record<string, any>,
    cmd: TFlashyCommands
  ): Observable<T> {
    return this.flashyStoreService.makeHttpRequest('POST', 'cmd', body, {
      cmd,
    });
  }
}
