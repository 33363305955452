import { Injectable, inject } from '@angular/core';
import { DefaultHttpUrlGenerator, Pluralizer, normalizeRoot } from '@ngrx/data';

import { AccountStore } from 'account/data-access';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpUrlGeneratorService extends DefaultHttpUrlGenerator {
  accountStore = inject(AccountStore);
  constructor(private pluralise: Pluralizer) {
    super(pluralise);
  }

  entityResource(entityName: string, root: string): string {
    return this.createUrl(entityName, root) + '/';
  }

  collectionResource(entityName: string, root: string): string {
    return this.createUrl(this.pluralise.pluralize(entityName), root);
  }

  // registerHttpResourceUrls(entityHttpResourceUrls?: EntityHttpResourceUrls | undefined): void {
  //   // console.log(entityHttpResourceUrls);
  // }

  private createUrl(entityName: string, root: string): string {
    const currentAccountId = this.accountStore.currentAccountId();
    const entity = entityName.toLowerCase();
    const pathArray = [normalizeRoot(root), entity];
    if (['virtual_numbers'].includes(entity)) {
      pathArray.splice(1, 0, 'services');
    }
    if (!['user', 'account', 'accounts'].includes(entity)) {
      if (!currentAccountId) {
        throw new Error('No currentAccountId');
      }
      pathArray.splice(1, 0, currentAccountId);
    }
    return pathArray.join('/');
  }
}
