import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultDataServiceConfig,
  EntityCollectionDataService,
  HttpUrlGenerator,
} from '@ngrx/data';

import { FlashyDataServiceService } from './flashy-data-service.service';

@Injectable()
export class FlashyDataServiceFactoryService {
  constructor(
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator,
    protected config: DefaultDataServiceConfig
  ) {
    // config = config || {};
    // httpUrlGenerator.registerHttpResourceUrls(config.entityHttpResourceUrls);
  }

  /**
   * Create a default {EntityCollectionDataService} for the given entity type
   * @param entityName Name of the entity type for this data service
   */
  create<T>(entityName: string): EntityCollectionDataService<T> {
    return new FlashyDataServiceService<T>(
      entityName,
      this.http,
      this.httpUrlGenerator,
      this.config
    );
  }
}
