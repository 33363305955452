import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { DefaultDataServiceConfig, HttpMethods } from '@ngrx/data';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { AccountStore } from 'account/data-access';
import { Brands, FlashyAccount } from 'account/domain';

import {
  EFlashyLanguages,
  EFlashyStatus,
  IFlashyServerResponse,
} from '../interfaces';

export interface SMSPrice {
  country: string;
  price: string;
  chars: number;
}

export interface FileDto {
  success: boolean;
  data: {
    path: string;
    thumbnail: string;
    ext: string;
    title: string;
    mime_type: string;
    size: number;
    type: string;
  };
}

export interface FlashyCountry {
  [key: string]: string;
}

export interface FlashyCurrency {
  [key: string]: string;
}

export interface FlashyTimezone {
  [key: string]: string;
}

export interface IUnsubscribeSettings {
  language: EFlashyLanguages;
  allow_delete: EFlashyStatus;
  text: string;
  sms: Record<
    string,
    Record<
      string,
      {
        status: boolean;
        editable: boolean;
        keywords: string[];
        action: string;
        reply: string;
      }
    >
  >;
}

export interface IRecommendationSettings {
  score: number;
  use_ai: boolean;
  variants: boolean;
}

export interface SMSPricing {
  price: number;
  chars: number;
  unicode?: boolean;
}

interface ITrackingSettingsParamField {
  value: string;
  options: Array<{ value: string; title: string }>;
}

interface ITrackingSettingsParam {
  title: string;
  status: boolean;
  campaign: ITrackingSettingsParamField;
  automation: ITrackingSettingsParamField;
}

export interface ITrackingSettings {
  status: boolean;
  parameters: ITrackingSettingsParam[];
}

export interface FlashyChangeLog {
  title: string;
  description: string;

  body: string;
  date: number;
  tags: string[];
}

export interface BusinessDataItemMinMax {
  min: number;
  max: number;
  mean: number;
  median: number;
  last_days: number;
  next_days: number;
}

export interface BusinessDataItemMostCommon {
  top: string;
  freq: number;
  unique: number;
  ratio: number;
}

export interface BusinessDataItem {
  frequency: {
    frequency_cluster: BusinessDataItemMinMax;
    frequency: BusinessDataItemMinMax;
  };
  recency: {
    recency_cluster: BusinessDataItemMinMax;
    recency: Omit<BusinessDataItemMinMax, 'median' | 'mean'>;
  };
  revenue_prediction: {
    six_months_pred: BusinessDataItemMinMax;
    one_year_pred: BusinessDataItemMinMax;
  };
  account_info: {
    most_common_month_period: BusinessDataItemMostCommon;
    most_common_day_part: BusinessDataItemMostCommon;
    most_common_day: BusinessDataItemMostCommon;
    most_common_season: BusinessDataItemMostCommon;
    most_loved_category: BusinessDataItemMostCommon;
  };
  product_info: {
    total_products_purchased: BusinessDataItemMinMax;
    monetary: BusinessDataItemMinMax;
    number_of_products: BusinessDataItemMinMax;
    average_price: BusinessDataItemMinMax;
    average_discount: BusinessDataItemMinMax;
  };
  count: number;
  segment_ratio: number;
}

export interface DataBusiness {
  about_to_sleep: BusinessDataItem;
  at_risk: BusinessDataItem;
  cant_lose_them: BusinessDataItem;
  champions: BusinessDataItem;
  hibernating: BusinessDataItem;
  loyal_customers: BusinessDataItem;
  need_attention: BusinessDataItem;
  new_customers: BusinessDataItem;
  potential_loyalists: BusinessDataItem;
  promising: BusinessDataItem;
  frequency: number;
  metadata: {
    total_contacts: number[];
    total_purchases: number[];
  };
}

export interface GraphsData {
  top_categories: Record<string, TopCategories>;
  top_brands: Record<string, TopBrands>;
  purchase_distribution: Record<string, Distribution>;
  cohort: Record<string, Analytics>;
}

export interface Distribution {
  number_of_accounts: number;
  number_of_purchases: number;
  percentage: number;
}

export interface TopBrands {
  avg_product_price: number;
  total_purchases: number;
  total_revenue: number;
}

export interface TopCategories {
  shopping_share: number;
  total_revenue: number;
  avg_product_price: number;
  shopping_share_pct: number;
}

export type RetentionRevenue = Record<string, number>;

export interface Analytics {
  retention: RetentionRevenue;
  revenue: RetentionRevenue;
  percentage: Record<string, number>;
}

export interface Products {
  products: Product[];
  frequency: number;
}

export interface Product {
  title: string;
  description: string;
  link: string;
  image_link: string;
  thumbnail: string;
  price: string;
  item_id: string;
}

export interface SupportHelpCenter {
  title: string;
  link: string;
  content: string;
}

// export interface IFlashyBrandSettings {
//   logo: string;
//
// }

@Injectable({
  providedIn: 'root', // create a new instance for each lazy module
})
export class FlashyStoreService {
  accountStore = inject(AccountStore);
  readonly root!: string;
  private readonly cache = new Map();
  private readonly reqLoading$ = new BehaviorSubject({ url: '', state: false });
  readonly isLoading$ = this.reqLoading$.pipe(
    switchMap(({ url, state }) => (this.isMutePath(url) ? EMPTY : of(state)))
  );
  // eslint-disable-next-line max-len
  private readonly publicPaths = [
    'helpers',
    'auth',
    'user',
    'partner',
    'forgot-password',
    'signup',
    'signout',
    'reset-password',
    'public',
    'health',
    'change-password',
    'invite',
    'sso',
  ];
  private readonly mutePaths = [
    'health',
    'ping',
    'countries',
    'currencies',
    'timezones',
    'signout',
  ];

  constructor(
    private httpClient: HttpClient,
    { root }: DefaultDataServiceConfig
  ) {
    this.root = root as string;
  }

  /**
   * Get the right path to get the data
   *
   * @param url url to parse
   */
  getHttpUrl(url?: string): string {
    if (url?.includes('http')) {
      return url;
    }
    if (this.publicPaths.some((s) => url?.includes(s))) {
      return `${this.root}/${url}`;
    }
    return `${this.root}/${this.accountStore.currentAccountId()}/${url}`;
  }

  isMutePath(url: string): boolean {
    // Mark as background requests
    return this.mutePaths.some((s) => url.includes(s));
  }

  includeCredentials(url: string): boolean {
    return ['signup'].includes(url);
  }

  /**
   * Make a custom http request to Flashy API
   * TODO catch errors
   */
  makeHttpRequest<T>(
    method: HttpMethods,
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any,
    params?: Params
  ): Observable<T | never> {
    return this.httpClient
      .request<IFlashyServerResponse<T>>(method, this.getHttpUrl(url), {
        responseType: 'json',
        withCredentials: this.includeCredentials(url),
        body,
        params,
        observe: 'events',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        switchMap((event) => {
          if (event.type === HttpEventType.Response) {
            this.reqLoading$.next({ url, state: false });
            return of(event.body);
          } else if (event.type === HttpEventType.Sent) {
            this.reqLoading$.next({ url, state: true });
          }
          return EMPTY;
        }),
        // catchError(err => {
        //   console.log(err);
        //   this.reqLoading$.next({url, state: false});
        //   return throwError(err);
        // }),
        switchMap(({ success, data, errors }) =>
          success ? of(data) : throwError(() => JSON.stringify(errors))
        ),
        finalize(() => {
          this.reqLoading$.next({ url, state: false });
        })
      );
  }

  /**
   * Get Flashy Helpers like Countries, Currencies and Timezones
   *
   * @param type Type of 'countries' | 'currencies' | 'timezones' | 'changelog'
   * @example storeService.getHelper<FlashyTimezone>('timezones')
   */
  getHelper<
    T = FlashyCountry | FlashyCurrency | FlashyTimezone | FlashyChangeLog,
  >(
    type: 'countries' | 'currencies' | 'timezones' | 'changelog'
  ): Observable<T> {
    if (this.cache.has(type) && type !== 'changelog') {
      return of(this.cache.get(type));
    }
    return this.makeHttpRequest<T>('GET', `helpers?type=${type}`).pipe(
      tap((res) => type !== 'changelog' && this.cache.set(type, res))
    );
  }

  getUnsubscribeSettings(): Observable<IUnsubscribeSettings> {
    return this.makeHttpRequest('GET', 'settings/unsubscribe_settings');
  }

  saveUnsubscribeSettings(settings: IUnsubscribeSettings): Observable<boolean> {
    return this.makeHttpRequest(
      'PUT',
      'settings/unsubscribe_settings',
      settings
    );
  }

  getRecommendationSettings(): Observable<IRecommendationSettings> {
    return this.makeHttpRequest('GET', 'recommendations/settings');
  }

  setRecommendationSettings(
    settings: IRecommendationSettings
  ): Observable<boolean> {
    return this.makeHttpRequest('POST', 'recommendations/settings', settings);
  }

  verifyAccount(
    data: Pick<FlashyAccount, 'name' | 'website' | 'phone'> & {
      message: string;
    }
  ): Observable<FlashyAccount> {
    return this.makeHttpRequest<FlashyAccount>('PUT', 'verify', data).pipe(
      tap((account) => this.accountStore.updateAccount(account))
    );
  }

  validateAction(action: 'contacts_action'): Observable<boolean> {
    return this.makeHttpRequest('GET', `lock/${action}`);
  }

  uploadFile<T>(
    url: string,
    formData: FormData,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: HttpParams | Record<string, any>
  ): Observable<T> {
    return this.httpClient.post<T>(this.getHttpUrl(url), formData, {
      responseType: 'json',
      params,
    });
  }

  smsPricing(country: string): Observable<SMSPricing> {
    return this.makeHttpRequest('GET', 'pricing/sms', undefined, { country });
  }

  getTrackingSettings(): Observable<ITrackingSettings> {
    return this.makeHttpRequest('GET', 'settings/tracking');
  }

  setTrackingSettings(
    settings: ITrackingSettings
  ): Observable<ITrackingSettings> {
    return this.makeHttpRequest('POST', 'settings/tracking', settings);
  }

  getBusinessData(): Observable<DataBusiness> {
    return this.makeHttpRequest('GET', 'business-intelligence/rfm');
  }

  getBusinessGraphs(): Observable<GraphsData> {
    return this.makeHttpRequest('GET', 'business-intelligence/graphs');
  }

  getBundles(): Observable<Products> {
    return this.makeHttpRequest('GET', 'business-intelligence/bundles');
  }

  submitATicket(data: FormData): Observable<boolean> {
    return this.uploadFile(this.root.replace('spa.', ''), data, {
      flashy_action: 'ticket',
    });
  }

  getSearchHelpCenter(
    query: string,
    lang: string
  ): Observable<SupportHelpCenter[]> {
    return this.httpClient.get<SupportHelpCenter[]>(
      this.root.replace('spa.', ''),
      {
        params: { flashy_action: 'search', page: 'post', query, lang },
      }
    );
  }

  getReportsReviews<T>(): Observable<T> {
    return this.httpClient.get<T>('https://json.flashy.dev/reviews-stats');
  }

  getBrandSettings(): Observable<Brands> {
    return this.makeHttpRequest('GET', 'settings/branding');
  }

  // for upload global file ( not related to any user )
  uploadGlobalFile(file: FormData): Observable<FileDto> {
    return this.httpClient.post<FileDto>(`${this.root}/file`, file, {
      responseType: 'json',
    });
  }
}
