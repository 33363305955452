import { Injectable, inject } from '@angular/core';
import { Pluralizer } from '@ngrx/data';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';

import { FlashyCustomDataService } from './FlashyCustomDataService';
import { IFlashyPagination } from './interfaces';
import { FlashyStoreService } from './service/store.service';

@Injectable({
  providedIn: 'root',
})
export abstract class FlashyPaginationService<
  T,
> extends FlashyCustomDataService<T> {
  readonly storeService = inject(FlashyStoreService);
  readonly pluralized = inject(Pluralizer);
  sortBy?: keyof T;
  isLoading$ = this.storeService.isLoading$.pipe(debounceTime(100));
  filteredEntities$ = this.entities$.pipe(
    map((entities) =>
      entities.sort(
        (a, b) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          b[this.sortBy || this.uniqueKey] - a[this.sortBy || this.uniqueKey]
      )
    )
  );
  abstract uniqueKey: keyof T;

  protected constructor(readonly entityName: string) {
    super();
  }

  get pluralPath(): string {
    return this.pluralized.pluralize(this.entityName.toLowerCase());
  }

  get singlePath(): string {
    return this.entityName.toLowerCase();
  }

  getQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: Record<string, any>,
    overwriteEntities = true
  ): Observable<T[]> {
    return this.storeService
      .makeHttpRequest<IFlashyPagination<T>>('GET', this.pluralPath, {}, params)
      .pipe(
        tap(({ results, total, count }) => {
          if (overwriteEntities) {
            if (results.length) {
              this.addEntities(results);
            }
            this.setCount(count);
            this.setTotal(total);
          }
        }),
        map(({ results }) => results)
      );
  }

  getByKey(key: number | string): Observable<T> {
    return this.storeService
      .makeHttpRequest<T>('GET', `${this.singlePath}/${key}`)
      .pipe(tap((newEntity) => this.updateOrAdd(newEntity)));
  }

  add(entity: T): Observable<T> {
    return this.storeService
      .makeHttpRequest<T>('POST', this.singlePath, entity)
      .pipe(tap((newEntity) => this.addEntity(newEntity)));
  }

  update(entity: Partial<T>): Observable<T> {
    return this.storeService
      .makeHttpRequest<T>(
        'PUT',
        `${this.singlePath}/${entity[this.uniqueKey]}`,
        entity
      )
      .pipe(tap((newEntity) => this.updateEntity(newEntity)));
  }

  delete(entity: T): Observable<void> {
    return this.storeService
      .makeHttpRequest<void>(
        'DELETE',
        `${this.singlePath}/${entity[this.uniqueKey]}`,
        entity
      )
      .pipe(tap(() => this.deleteEntities([entity])));
  }
}
