import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const enum EFlashyPopupSettingsResponseType {
  CLOSE = 'close',
  REDIRECT = 'redirect',
  SECOND = 'second',
  NEW_POPUP = 'new_popup',
}

export const enum EFlashyPopupConditionsVisitorType {
  ALL = 'all',
  NEW = 'new',
  RETURN = 'return',
}

export const enum EFlashyPopupConditionsFrequencyType {
  DAILY = 'daily',
  FEW_DAYS = 'few_days',
  EVERY_SESSION = 'every_session',
  EVERY_PAGE = 'every_page',
  ALWAYS = 'always',
}

export const enum EFlashyPopupSettingsCountType {
  FIRST_SCREEN = 'first_screen',
  SECOND_SCREEN = 'second_screen',
  NONE = 'none',
}

export const enum EFlashyImportStatus {
  FILE_UPLOADED,
  IN_PROCESS,
  COMPLETED,
  LIST_SELECTED,
}

export interface IFlashyServerResponse<T> {
  success: boolean;
  data: T;
  errors?: { [key: string]: string };
}

export interface IUpgradeRequired {
  message: string;
  level: number;
  upgrade: boolean;
}

export const enum EFlashyPermission {
  NONE,
  VIEWER,
  EDITOR,
}

export interface IFlashyRoles {
  contacts: EFlashyPermission;
  templates: EFlashyPermission;
  campaigns: EFlashyPermission;
  reports: EFlashyPermission;
  automations: EFlashyPermission;
  popups: EFlashyPermission;
  billing: EFlashyPermission;
  business: EFlashyPermission;
  performance: EFlashyPermission;
  users: EFlashyPermission;
  contacts_type: 'all' | 'owned';
}

export interface IFlashyStoreConfig {
  root?: string;
}

export const enum EFlashyDirections {
  LTR = 'ltr',
  RTL = 'rtl',
}

export const enum EFlashyStatus {
  FALSE,
  TRUE,
}

export const enum EFlashyLanguages {
  EN = 'en',
  HE = 'he',
}

export const enum EFlashyCategories {
  ALL = 'all',
  ECOMMERCE = 'ecommerce',
  SAAS = 'saas',
  BLOGS = 'blogs',
  GAMING = 'gaming',
}

export const enum EFlashyObjectives {
  ONBOARDDING = 'onboarding',
  LEADS = 'leads',
  SALES = 'sales',
  TRAFFIC = 'traffic',
  NURTURING = 'nurturing',
  TRANSACTIONAL = 'transactional',
  ABANDONMENT = 'abandonment',
}

export const enum EFlashyDesigns {
  ALL = 'all',
  POPUP = 'popup',
  EMBEDDED = 'embedded',
  LANDING_PAGE = 'landing_page',
}

export const enum EFlashyResourceType {
  TEMPLATES = 'templates',
  EMAIL = 'email',
}

export const enum EFlashyFolderResource {
  TEMPLATES = 'templates',
  WIDGETS = 'widgets',

  ELEMENTS = 'elements',
  AUTOMATIONS = 'automations',
  POPUPS = 'popups',
}

export const enum EFlashyCampaignStatus {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  SENDING = 'in_process',
  SENT = 'sent',
  BLOCKED = 'blocked',
}

export const enum EFlashyCampaignHtmlType {
  BUILDER = 'builder',
  CODE = 'code',
  EDITOR = 'editor',
}

export interface IFlashyConditions {
  audience: {
    visitors: boolean;
    contacts: boolean;
  };
  devices: {
    desktop: boolean;
    mobile: boolean;
  };
  on_exit: boolean;

  visitor_type: EFlashyPopupConditionsVisitorType;
  frequency_type: EFlashyPopupConditionsFrequencyType;
  converted: boolean;

  on_timeout: boolean;
  timeout: number;
  on_scroll: boolean;
  scroll: number;
  on_addtocart: boolean;
  on_purchase: boolean;
  on_hover: boolean;
  hover: string;
  on_click: boolean;
  days: number;
  on_custom: boolean;
  custom: string;
  conditions: IFlashyUiConditions | undefined; // TODO add conditions interface
  contact_conditions: IFlashyUiConditions | undefined; // TODO add contact_conditions interface
}

export interface IFlashyUiConditions {
  operator: 'and' | 'or';
  groups: IFlashyUiConditionGroup[];
  rules?: [];
  filters?: [];
}

export interface IFlashyUiConditionGroup {
  operator: 'and' | 'or';
  fields: IFlashyUiConditionField[];
  events?: IFlashyUiConditionEvents[];
  groups?: IFlashyUiConditionGroup[];
}

export interface IFlashyUiConditionField {
  title: string;
  type: string;
  column: string;
  operator: string;
  value?: string | number | { text: string }[];
  value_end?: string;
  period?: string;
  hidden?: boolean;
}

export interface IFlashyUiConditionEvents {
  title: string;
  type: string;
  timeline: string;

  filter_by?: string;
  value?: string | number;
  at?: 'am' | 'pm';
  query?: string;
  operator?: string;
  timezone?: string;
  time?: string | number;
  period?: 'm' | 'h' | 'd' | 'w';
  status?: string;
  automation_status?: string;
  request_type?: 'get' | 'post';
  url?: string;
  key_type?: string;
  key?: string;
  campaign_status?: string;
  template_id?: string;
}

export interface IFlashySettings {
  objective: EFlashyObjectives;
  tracking: string;
  injectInto: string;
  relation: 'in' | 'after' | 'before';
  count_views: EFlashyPopupSettingsCountType;
  response: {
    type: EFlashyPopupSettingsResponseType;
    link: string;
    new_popup_id: number;
  };
  feed_id: number;
}

export interface IScheduleTask {
  catalog_id: number;
  url: string;
  type: string;
  every: number;
  on: string;
  period: 'minute' | 'weekly';
  delete_on_finish: false;
  attempts: number;
  fails: number;
  schedules: number;
  reschedule: true;
}

export const FLASHY_STORE_CONFIG_TOKEN = new InjectionToken<IFlashyStoreConfig>(
  'Flashy store default configuration'
);

export interface IFlashyPagination<T> {
  results: T[];
  // Total count of all resources no matter of search results.
  count: number;
  // Total count of returned results based on search query.
  total: number;
}

export interface IFlashyEntityReport {
  errors: number;
  existings: number;
  success: number;
  updates: number;
  reasons?: Record<string, number>;
}

export interface IShareEntity {
  partner: {
    company_info: string;
    key: string;
    name: string;
    website: string;
    logo?: string;
  };
  share: {
    category: string[];
    created_at: number;
    desc: string;
    description: string;
    design: null;
    direction: 'ltr' | 'rtl';
    feature: 0;
    hidden: EFlashyStatus;
    id: number;
    integrations: null;
    key: string;
    language: EFlashyLanguages;
    objective: EFlashyObjectives;
    password: null;
    price: number;
    purchases: number;
    rating: number;
    rating_users: number;
    source_id: number;
    stats: number;
    status: EFlashyStatus;
    tags: string[];
    thumbnail: string[];
    title: string;
    type: 'automations' | 'popups' | 'templates';
    updated_at: number;
    user_id: number;
    uses: number;
  };
}

export interface IFlashyProducts {
  title: string;
  link: string;
  description: string;
  thumbnail: string;
  image_link: string;
  price: string;
}

export interface FlashyCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}
