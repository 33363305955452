import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class FlashyCustomDataService<T> {
  entities$ = new BehaviorSubject<T[]>([]);
  count$ = new BehaviorSubject(0);
  total$ = new BehaviorSubject(0);
  private cache = new Map<unknown, T>();
  abstract uniqueKey: keyof T;
  abstract isLoading$: Observable<boolean>;

  get entities(): T[] {
    return this.entities$.getValue();
  }

  get count(): number {
    return this.count$.getValue();
  }

  get total(): number {
    return this.total$.getValue();
  }

  next(): void {
    this.entities$.next([...this.cache.values()]);
  }

  entityIsCached(entity: T): boolean {
    return this.cache.has(entity[this.uniqueKey]);
  }

  addEntities(entities: T[]): void {
    entities.forEach((entity) => {
      this.cache.set(entity[this.uniqueKey], entity);
    });
    this.next();
  }

  setEntities(entities: T[]): void {
    this.cache.clear();
    entities.forEach((entity) => {
      this.cache.set(entity[this.uniqueKey], entity);
    });
    this.next();
  }

  addEntityOnIndex(entity: T, index: number): void {
    const entities = this.entities.slice();
    entities.splice(index, 0, entity);
    this.cache.set(entity[this.uniqueKey], entity);
    this.entities$.next(entities);
    this.setCount(this.count + 1);
  }

  addEntity(entity: T): void {
    this.cache.set(entity[this.uniqueKey], entity);
    this.next();
    this.setCount(this.count + 1);
  }

  deleteEntities(entities: T[]): void {
    entities.forEach((entity) => {
      this.cache.delete(entity[this.uniqueKey]);
    });
    this.next();
    this.setCount(this.count - entities.length);
  }

  updateOrAdd(entity: T): void {
    const isCached = this.entityIsCached(entity);
    if (isCached) {
      this.updateEntity(entity);
    } else {
      this.addEntity(entity);
    }
  }

  updateEntity(entity: T): void {
    const indexOf = this.entities.findIndex(
      (ent) => ent[this.uniqueKey] === entity[this.uniqueKey]
    );
    const entities = this.entities.slice();
    if (indexOf !== -1) {
      this.cache.set(entity[this.uniqueKey], entity);
      entities.splice(indexOf, 1, entity);
      this.entities$.next(entities);
    }
  }

  setCount(count: number): void {
    this.count$.next(count);
  }

  setTotal(count: number): void {
    this.total$.next(count);
  }
}
