import { EntityDataModuleConfig, EntityMetadataMap } from '@ngrx/data';

interface Entity {
  id: number;
  created_at: number;
  updated_at: number;
}

interface AccountEntity {
  account: number;
}

// interface ContactEntity {
//   contact_id: string;
// }

interface KeyEntity {
  key: string;
}

const entityMetadata: EntityMetadataMap = {
  // Contact: {
  //   selectId: ({contact_id}: ContactEntity) => contact_id,
  // },
  Template: {
    filterFn: (entities: Entity[]) => entities.sort((a, b) => b.id - a.id),
  },
  Segment: {},
  Review: {},
  'Template-Resource': {},
  'Automation-Resource': {},
  Automation: {
    filterFn: (entities: Entity[]) => entities.sort((a, b) => b.id - a.id),
  },
  Invite: {},
  Integration: {},
  'Popup-Resource': {},
  'Email-Campaign': {
    filterFn: (entities: Entity[]) => entities.sort((a, b) => b.id - a.id),
  },
  'Sms-Campaign': {
    filterFn: (entities: Entity[]) => entities.sort((a, b) => b.id - a.id),
  },
  User: {},
  'Account-User': {},
  Domain: {},
  'Api-Key': {
    selectId: ({ key }: KeyEntity) => key,
  },
  Store: {},
  Note: {},
  Virtual_Number: {},
  Property: {
    selectId: ({ key }: KeyEntity) => key,
  },
  Widget: {
    filterFn: (entities: Entity[]) => entities.sort((a, b) => b.id - a.id),
  },
  Folder: {},
  Catalog: {},
  Invoice: {},
  Profile: {},
  List: {
    filterFn: (entities: Entity[]) =>
      entities.sort((a, b) => b.updated_at - a.updated_at),
  },
  File: {},
  Popup: {
    filterFn: (entities: Entity[]) => entities.sort((a, b) => b.id - a.id),
  },
  Account: {
    selectId: ({ account }: AccountEntity) => account,
  },
};

const pluralNames = {
  Property: 'Properties',
};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames,
};
