import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { createNoopInjectionToken } from 'ngxtension/create-injection-token';

// Core config interface.
export type FlashyCoreConfig = {
  stage: 'production' | 'dev-production' | 'development';
};

// The class that creates the config object.
class FlashyConfig {
  constructor(readonly config: FlashyCoreConfig) {}

  // A getter that returns true if the stage is production.
  get production(): boolean {
    return this.config.stage === 'production';
  }
}

// The injection token for the config object.
export const [injectCoreConfig, provideCoreConfig] =
  createNoopInjectionToken<FlashyConfig>('FlashyCoreConfig');

// The function that provides the config object.
export function provideFlashyCore(
  config: FlashyCoreConfig
): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideCoreConfig(() => new FlashyConfig(config)),
  ]);
}
