import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import {
  DefaultDataServiceConfig,
  DefaultDataServiceFactory,
  HttpUrlGenerator,
  Pluralizer,
  provideEntityData,
  withEffects,
} from '@ngrx/data';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';

import { injectCoreConfig } from '@flashy/core';

import { entityConfig } from './entity-metadata';
import { FlashyDataServiceFactoryService } from './flashy-data-service-factory.service';
import { CustomHttpUrlGeneratorService } from './http-url-generator/custom-http-url-generator.service';
import { FLASHY_STORE_CONFIG_TOKEN, IFlashyStoreConfig } from './interfaces';

export function createDefaultDataServiceConfig(
  config: IFlashyStoreConfig
): DefaultDataServiceConfig {
  const { production } = injectCoreConfig();
  let root = 'https://spa.flashy.app';
  if (!production) {
    root = config.root || 'https://spa.flashy.dev';
  }
  return { root };
}

export function createCustomHttpUrlGeneratorService(
  pluralise: Pluralizer
): HttpUrlGenerator {
  return new CustomHttpUrlGeneratorService(pluralise);
}

export function provideFlashyStore(
  config: Partial<IFlashyStoreConfig> = {}
): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideStore(),
    provideEffects([]),
    provideEntityData(entityConfig, withEffects()),
    {
      provide: DefaultDataServiceConfig,
      useFactory: createDefaultDataServiceConfig,
      deps: [FLASHY_STORE_CONFIG_TOKEN],
    },
    {
      provide: HttpUrlGenerator,
      useFactory: createCustomHttpUrlGeneratorService,
      deps: [Pluralizer],
    },
    {
      provide: DefaultDataServiceFactory,
      useClass: FlashyDataServiceFactoryService,
    },
    { provide: FLASHY_STORE_CONFIG_TOKEN, useValue: config },
  ]);
}
